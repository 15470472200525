import React from 'react';
import { Input, Label, Wrapper } from './Slider.styles';

const Slider = (props) => {
  return (
    <Wrapper {...props}>
      <Input type='range' {...props}></Input>
      <Label {...props}>
        REDEEM UP TO<span> {props.max}</span> LCs{' '}
      </Label>
    </Wrapper>
  );
};

export default Slider;
