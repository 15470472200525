import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

const ShareIcons = ({ title, url, twitterHandle, tags }) => {
  return (
    <div
      style={{
        margin: '0 1rem',
        width: '140px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>

      <TelegramShareButton url={url} title={title}>
        <TelegramIcon size={30} round={true} />
      </TelegramShareButton>
    </div>
  );
};
export default ShareIcons;
