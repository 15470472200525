import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: ${({ theme }) => theme.navHeight};
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
  height: 100%;
  max-height: ${({ theme }) => `calc(100vh - ${theme.navHeight})`};
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.lightBgColorRGBA};

  h3 {
    text-align: center;
    margin: 1rem;
  }
`;

export const CloseBtn = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 15px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;

export const ItemList = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-bottom: 1rem;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #daed08 ${({ theme }) => theme.lightBgColorRGBA};
  scrollbar-gutter: stable;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${({ theme }) => theme.lightBgColorRGBA};
    border-radius: 5px;
  }
  /* &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.lightBgColorRGBA};
  } */
  &::-webkit-scrollbar-thumb {
    background-color: #daed08;
    border-radius: 5px;
  }
`;

export const Controls = styled.div`
  width: 100%;
  height: 450px;
  background: ${({ theme }) => theme.lightBgColorRGBA};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  select {
    display: inline-block;
    position: relative;
    width: 100%;
    appearance: none;
    background: ${({ theme }) => theme.bgColor};
    border: ${({ theme }) => theme.borderMuted};
    border-radius: 6px;
    color: ${({ theme }) => theme.fontColor};
    padding: 12px 0 12px 10%;
    font: ${({ theme }) => theme.font.body};
    font-weight: 800;
    outline: none;
    cursor: pointer;
  }
  .select {
    position: relative;
    min-width: 150px;
    width: 50%;
    margin: 1rem auto;
  }
  .select::after {
    content: '';
    position: absolute;
    height: 8px;
    width: 11px;
    background-image: url('/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
  }

  option {
    background: ${({ theme }) => theme.bgColor};
  }

  .row {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 0.25rem;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;

  h6 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
