import styled from 'styled-components';

export const Container = styled.div`
  margin: ${({ theme }) => `${theme.navHeight} auto 0`};
  min-height: 500px;
  width: 100%;
  max-width: 1280px;
  padding: 2rem 4rem;

  iframe {
    width: 95%;
    max-width: 560px;
    aspect-ratio: 16/9;
    border: none;
  }

  .logo {
    display: block;
    height: 45px;
    margin: 0 auto 1.5rem;
  }
  .tag-line {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 1.2px;
    font-style: italic;
    width: 100%;
    text-align: center;
    margin-bottom: 4.5rem;

    span {
      font-weight: 700;
    }
  }

  video {
    width: 100%;
    max-width: 900px;
    margin: 2rem 0;
    border-radius: 1rem;
    border: 4px solid;
    border-color: ${({ theme }) => theme.lightPurple};
  }

  .blurb {
    width: 95%;
    max-width: 700px;
    margin-bottom: 2.5rem;
  }

  @media screen and (max-width: 860px) {
    iframe {
      width: 95%;
    }
    .logo {
      height: unset;
      width: 80%;
    }
  }
  @media screen and (max-width: 570px) {
    padding: 0.5rem;
    .tag-line {
      font-size: 1.2rem;
    }
  }
`;

export const Hero = styled.div`
  display: flex;
  position: relative;
  max-width: 730px;
  max-height: 375px;
  margin: 0 auto 3rem;

  .btn {
    position: absolute;
    width: 280px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 860px) {
    img {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 760px) {
    .btn {
      width: 200px;
    }
  }
  @media screen and (max-width: 5250px) {
    .btn {
      width: 180px;
      top: -40px;
    }
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ spacing }) => (spacing ? spacing : 'space-between')};
  width: 100%;
  max-width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : 'auto')};
  margin-bottom: 2.5rem;
  margin: ${({ margin }) => (margin ? margin : null)};

  @media screen and (max-width: 1195px) {
    justify-content: center;
  }
  @media screen and (max-width: 485px) {
    justify-content: center;
  }
`;

export const FlexStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ spacing }) => (spacing ? spacing : 'space-between')};
  width: 100%;
  max-width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : 'auto')};
  margin-bottom: 2.5rem;
  margin: ${({ margin }) => (margin ? margin : null)};
  gap: ${({ gap }) => (gap ? gap : null)};
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ spacing }) => (spacing ? spacing : 'space-around')};
  width: 95%;
  max-width: 600px;
  margin-bottom: 2rem;
  margin: ${({ margin }) => (margin ? margin : null)};
`;

export const Logo = styled.img`
  height: 30px;
  margin: 0 1.5rem 1rem 0;
`;

export const PackWrapper = styled.div`
  position: relative;
  width: 220px;
  height: 400px;
  perspective: 75px;

  .price-tag {
    position: absolute;
    top: 0;
    left: 0;
    height: 55px;
    width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.25rem;
    background-image: url('/images/icon--tag.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 5;
  }

  .btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    height: 320px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: 0 center;
    transform-style: preserve-3d;
    /* z-index: 5; */
    transition: all 0.5s ease-in-out;

    &:hover {
      animation: jitter 2000ms ease-in-out infinite;
    }
  }

  @keyframes jitter {
    0% {
      transform: rotateZ(0) translateX(-50%);
    }
    2% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    5% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    10% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    15% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    20% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    25% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    30% {
      transform: translateX(-50%);
    }
    35% {
      transform: translateX(-50%);
    }
    40% {
      transform: translateX(-50%);
    }
    45% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    50% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    55% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    60% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    65% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    70% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    75% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    80% {
      transform: rotateY(-1deg) rotateZ(2deg) translateX(-50%);
    }
    85% {
      transform: rotateY(1deg) rotateZ(-2deg) translateX(-50%);
    }
    90% {
      transform: translateX(-50%);
    }
    95% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
`;
