import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0 .8rem 0 0'};
  position: relative;
  cursor: pointer;
`;

export const Main = styled.div`
  height: 42px;
  width: 42px;

  border-radius: 50%;
  background-color: #979090;
  position: relative;
  img {
    width: 24px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -45%);
  }
`;

export const Count = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffffff;
  color: black;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(45%, -45%);

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
`;
