/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Image,
  Description,
  Total,
  Trash,
} from './CheckoutItem.styles';
import Counter from '../Counter/Counter.component';

const CheckoutItem = ({ item, update }) => {
  const [count, countSet] = useState(item.qty);

  useEffect(() => {
    update({ item: item?.variant_id || item?.product_id, count });
  }, [count]);

  return (
    <Container>
      <Image src={item?.thumbnail || '/images/cardPack.png'} />
      <Description>
        <p>{item?.name}</p>
        <div className='row'>
          <Counter
            qty={item.qty}
            qtySet={countSet}
            max={item.maxPurchase || undefined}
            showMax={false}
          />
          <Total>${(item.price * parseInt(item?.qty)).toFixed(2)}</Total>
        </div>
      </Description>
      <Trash>
        <img
          src='/images/icon--trash-red.svg'
          alt='Delete Item'
          onClick={() => countSet(0)}
        />
      </Trash>
    </Container>
  );
};

export default CheckoutItem;
