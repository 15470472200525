import styled from 'styled-components';

export const Container = styled.div`
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  position: relative;
`;

export const Button = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.fontMuted};
  border: 1px solid black;

  &:active {
    scale: 0.95;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(0.7);
  }

  img {
    width: 18px;
  }
`;

export const Count = styled.div`
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.fontColor};
  color: black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  .counter-text {
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }
`;

export const Limit = styled.p`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px));
  color: ${({ theme }) => theme.fontMuted};
  font-size: 0.8rem;
`;
