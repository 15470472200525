/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Icon } from '../Icons/BaseIcon';
import {
  Container,
  Menu,
  MenuItems,
  Link,
  MenuTitle,
} from './DropDownMenu.styles';
import { NavLink } from 'react-router-dom';
// import { HandleOutsideDropdown } from '../../utils/utils';

const activeStyle = {
  fontWeight: '700',
  backgroundColor: 'rgba(255,255,255,.2',
};

const DropDownMenu = ({ icon, text, linkData }) => {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);
  // const { ref, isComponentVisible } = HandleOutsideDropdown();

  const handleClickOutside = useCallback((event) => {
    // if clicked outside, set to false to trigger toggle action in component
    if (ref.current.classList.contains('visible')) {
      console.log('setFalse');
      setShowMenu(false);
    } else if (
      !ref.current.classList.contains('visible') &&
      event.target.classList.contains('dropdown') &&
      ref.current.id === event.target.id
    ) {
      setShowMenu(true);
    }
  });

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return (
    <Container className='dropdown'>
      <MenuTitle className='dropdown' id={text || 'Logo'}>
        {text && text}
        {icon && (
          <Icon
            className='dropdown'
            id={text || 'Logo'}
            src={icon.src}
            height={icon.height}
            width={icon.width}
          />
        )}
      </MenuTitle>
      <Menu
        id={text || 'Logo'}
        className={showMenu ? 'visible' : null}
        ref={ref}
        // onClick={() => {
        //   console.log('MenuClick setFalse');
        //   setShowMenu(false);
        // }}
      >
        <MenuItems>
          {linkData.map((link, i) => {
            if (link.type === 'internal') {
              return (
                <NavLink
                  key={i}
                  to={link.link}
                  exact={link.exact}
                  activeStyle={activeStyle}
                >
                  <Link>
                    {link.icon && (
                      <Icon
                        src={link.icon.src}
                        width={link.icon.width}
                        height={link.icon.height}
                      />
                    )}
                    {link.text}
                  </Link>
                </NavLink>
              );
            } else if (link.type === 'external') {
              return (
                <a href={link.link} key={i}>
                  <Link>
                    {link.icon && (
                      <Icon
                        src={link.icon.src}
                        width={link.icon.width}
                        height={link.icon.height}
                      />
                    )}
                    {link.text}
                  </Link>
                </a>
              );
            } else return null;
          })}
        </MenuItems>
      </Menu>
    </Container>
  );
};

export default DropDownMenu;
