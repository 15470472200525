import React, { useState, useEffect, useContext } from 'react';
import { Container, Main, Count } from './CartIndicator.styles';
import { UserContext } from '../../context/UserContext';
const CartIndicator = (props) => {
  const { toggleCart } = props;
  const { cart } = useContext(UserContext);

  return (
    <Container {...props}>
      <Main>
        <img src='/images/icon--cart-black.svg' alt='' />
        <Count>
          <p>{cart?.count || 0}</p>
        </Count>
      </Main>
    </Container>
  );
};

export default CartIndicator;
