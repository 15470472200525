/* eslint-disable react-hooks/exhaustive-deps */
import '@particle-network/connect-react-ui/dist/index.css';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import Web3 from 'web3';
import config from '../config';
import { USER_REJECTED, chains } from '../constants';
import {
  useParticleProvider,
  useAccount,
  useParticleConnect,
  useConnectId,
  useSwitchChains,
} from '@particle-network/connect-react-ui';
import { Auth } from 'aws-amplify';

const useWeb3 = () => {
  const [web3, setWeb3] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const particleProvider = useParticleProvider();
  const account = useAccount();
  const particleId = useConnectId();
  const { cacheconnect, connectKit, connect } = useParticleConnect();
  const { ethNetworkName, ethChainId, maticNetworkName, maticChainId, ethRpc } =
    config;

  useEffect(() => {
    checkCachedProvider();
  }, [user]);

  useEffect(() => {
    createWeb3();
  }, [particleProvider]);

  // useEffect(() => {
  //   console.log('Account', account);
  //   const token = localStorage.getItem('token');
  //   if (!token || !account || user === null) return;
  //   if (
  //     particleId === 'particle' &&
  //     user?.internal_wallet !== account?.toLowerCase()
  //   ) {
  //     console.log('Setting Particle Wallet');
  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       internal_wallet: account?.toLowerCase(),
  //     }));
  //   } else if (
  //     particleId !== 'particle' &&
  //     user?.external_wallet !== account?.toLowerCase()
  //   ) {
  //     console.log('Setting External Wallet');
  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       external_wallet: account?.toLowerCase(),
  //       external_wallet_client: particleId,
  //     }));
  //   }
  //   console.log('Setting current Wallet');
  //   setUser((prevUser) => ({ ...prevUser, wallet: account?.toLowerCase() }));
  // }, [account]);

  async function createWeb3() {
    try {
      setWeb3(new Web3(particleProvider || ethRpc));
    } catch (err) {
      if (err.code === USER_REJECTED) return;
      console.log(err);
    }
  }

  async function setActiveWallet(client) {
    console.log('Setting Active Wallet', client);
    if (client === 'particle') {
      console.log('Particle Wallet');
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      await connect({
        id: 'particle',
        preferredAuthType: 'jwt',
        account: token,
        hideLoading: true,
      });
    } else if (client === 'external') {
      console.log('External Wallet');
      const data = await connect({ id: user?.external_wallet_client });
      console.log(data);
    } else {
      console.log('Invalid Client');
    }
  }

  async function checkCachedProvider() {
    // const cachedId = connectKit.cachedProviderId();
    try {
      await cacheconnect();
    } catch (err) {
      if (!err?.message && err?.includes('No current user')) return;
      console.log(err?.message || err);
      return;
    }
  }

  const checkChainId = async (chainId) => {
    let chain = connectKit.particle.getChain();
    if (chain?.id === chainId) return;
    switch (chainId) {
      case ethChainId:
        await connectKit.switchChain({ name: ethNetworkName, id: ethChainId });
        break;
      case maticChainId:
        await connectKit.switchChain({
          name: maticNetworkName,
          id: maticChainId,
        });
        break;
      default:
        console.log('Invalid Chain');
        break;
    }
  };

  return { web3, account, particleId, setActiveWallet, checkChainId };
};

export default useWeb3;

export const fromWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.fromWei(amount, to);
};
export const toWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.toWei(amount, to);
};

export const toChecksumAddress = (address) => {
  const web3 = new Web3();
  if (web3.utils.isAddress(address)) {
    return web3.utils.toChecksumAddress(address);
  }
  return null;
};
