import styled from 'styled-components';

export const Container = styled.div`
  height: 70px;
  width: 300px;
  display: flex;
  padding: 5px;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  background: rgba(41, 37, 62, 0.85);
  border-radius: 1rem;
`;

export const Image = styled.img`
  max-width: 25px;
  max-height: 62px;
  opacity: 0.3;
`;

export const Text = styled.p`
  text-align: center;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.2rem;
  width: 225px;
`;
