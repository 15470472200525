/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/UserContext';
import {
  BorderWrapper,
  Container,
  Content,
  ImageWrapper,
  Column,
  Row,
  PriceContainer,
} from './ProductView.styles';

import config from '../../config';
import StdButton from '../../components/StdButton/StdButton.component';
// import UserSignup from './UserSignup.component';
import ShareIcons from '../../components/ShareIcons/ShareIcons';
import useGA from '../../utils/useGA';
import PackContents from '../../components/PackContents/PackContents.component';
import ColorPicker from '../../components/ColorPicker/ColorPicker.component';
import Counter from '../../components/Counter/Counter.component';
import ProductSlider from '../../components/ProductSlider/ProductSlider.component';
import { getMerchItem } from '../../utils/api';

const ProductView = (props) => {
  const id = props.match.params.id;
  const { productType: type } = props;
  const { rewardPercentage, lcConversionRate } = config;
  const [product, setProduct] = useState(null);
  const { user, setUser, setCart } = useContext(UserContext);
  const history = useHistory();
  const [rewardPoints, rewardPointsSet] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [wishlist, setWishlist] = useState(false);
  const [loginRequired, loginRequiredSet] = useState(false);
  const { pageview } = useGA();
  const [selectedColor, selectedColorSet] = useState(null);
  const [selectedSize, selectedSizeSet] = useState();
  const [currentVariant, currentVariantSet] = useState(null); // product.variants[`${[product.id]}-${selectedColor}-${selectedSize}`.replace('.', '')
  const [activeThumb, activeThumbSet] = useState(0);
  const [qty, qtySet] = useState(1);
  const [totalPrice, totalPriceSet] = useState(0);
  const [showCheck, showCheckSet] = useState(false);

  useEffect(() => {
    getMerchItem(id).then((p) => {
      selectedColorSet(p.colors[0].name);
      selectedSizeSet(p.sizes[0]);
      setProduct(p);
    });
  }, []);

  useEffect(() => {
    if (!product || !selectedColor || !selectedSize) {
      return;
    }
    const variant =
      product.variants[
        `${[product.id]}-${selectedColor}-${selectedSize}`.replace('.', '')
      ];
    const total = parseFloat(variant?.price) * qty;
    rewardPointsSet(Math.floor(total * rewardPercentage * lcConversionRate));
    totalPriceSet(total);
    currentVariantSet(variant);
  }, [selectedColor, selectedSize, qty, product]);

  const addToCart = () => {
    //TODO: rethink Items for ease of updating.... map? object?
    setCart((prev) => {
      const items = prev?.items || new Map();
      items.set(currentVariant.id, {
        product_id: currentVariant.sync_product_id,
        variant_id: currentVariant.id,
        size: selectedSize,
        price: parseFloat(currentVariant.price),
        name: currentVariant.name,
        color: selectedColor,
        thumbnail: product?.imageThumb[activeThumb],
        qty: qty,
        productType: product.productType,
      });
      return {
        items,
        count: items?.size || 0,
      };
    });
    showCheckSet(true);
    setTimeout(() => {
      showCheckSet(false);
    }, 3000);
  };

  const setSize = (e) => {
    selectedSizeSet(e.target.value);
  };

  return (
    <Container>
      {product && (
        <Content>
          <Column>
            <h4
              className='back'
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </h4>
            <ImageWrapper>
              <div className='image-container'>
                <img
                  className='main-image'
                  src={product?.images?.[activeThumb]}
                  alt={product.name}
                />
              </div>
            </ImageWrapper>
            <ProductSlider
              width='550px'
              margin='1rem auto'
              thumbs={product?.imageThumb}
              setActive={activeThumbSet}
              activeThumb={activeThumb}
            />
          </Column>
          <Column>
            <h3>{product.name}</h3>
            <pre>{product.description}</pre>
            <Row justifyContent='space-around'>
              <Row justifyContent='center' margin='0 1rem'>
                {product.colors.map((color) => (
                  <ColorPicker
                    className='color-box'
                    key={color.name}
                    color={color}
                    selectedColor={selectedColor}
                    count={product.colors.length}
                    onClick={() => selectedColorSet(color.name)}
                  ></ColorPicker>
                ))}
              </Row>
              <div className='size-select'>
                <BorderWrapper>
                  <select onChange={setSize} value={selectedSize}>
                    {product.sizes.map((size, i) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </BorderWrapper>
              </div>
            </Row>
            <Row justifyContent='space-around'>
              <Counter product={product} qty={qty} qtySet={qtySet} />
              <PriceContainer>${totalPrice.toFixed(2)}</PriceContainer>
              {showCheck && (
                <img
                  src='/images/check.gif'
                  alt='check'
                  className='check'
                  key={currentVariant.id}
                />
              )}
            </Row>
            <StdButton
              variant='buy'
              width='90%'
              margin='.5rem auto 1rem'
              disabled={currentVariant && currentVariant?.status !== 'active'}
              onClick={addToCart}
            >
              {currentVariant && currentVariant?.status !== 'active'
                ? 'Out of Stock'
                : 'Add To Cart'}
            </StdButton>
            <div className='reward'>
              <p>Reward Points: </p>
              <p className='points'>
                {rewardPoints} <span>LCs</span>
              </p>
            </div>
          </Column>
        </Content>
      )}
    </Container>
  );
};

export default ProductView;
