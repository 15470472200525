/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import profanityList from './profanity.json';
import crypto from 'crypto';


export const HandleOutsideClick = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    // if clicked outside, set to false to trigger toggle action in component
    if (
      ref &&
      ref.current &&
      !ref.current.contains(event.target) &&
      isComponentVisible
    ) {
      event.stopPropagation();

      setIsComponentVisible(false);
    }
    // reset component to initial value
    setIsComponentVisible(true);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);
  return { ref, isComponentVisible, setIsComponentVisible };
};

export const HandleOutsideDropdown = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    // if clicked outside, set to false to trigger toggle action in component
    if (
      ref.current.classList.contains('visible') &&
      !event.target.classList.contains('dropdown')
    ) {
      setIsComponentVisible(false);
    }
    setIsComponentVisible(true);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);
  return { ref, isComponentVisible, setIsComponentVisible };
};

// parse card id from token
export const parseCardId = (tokenId) => {
  const cardIdArr = [];
  cardIdArr.push(tokenId.slice(1, 4));
  cardIdArr.push(tokenId.slice(4, 7));
  cardIdArr.push(tokenId.slice(7, 10));
  cardIdArr.push(tokenId.slice(10, 13));
  cardIdArr.push(tokenId.slice(13, 16));
  const cardId = cardIdArr.join('-');
  return cardId;
};

// split card Id to separate attributes
export const splitCardId = (cardId) => {
  let set = cardId.slice(0, 3);
  let pack = cardId.slice(4, 7);
  let rarity = cardId.slice(8, 11);
  let vRarity = cardId.slice(12, 15);
  let cardNum = cardId.slice(16);
  return { set, pack, rarity, vRarity, cardNum };
};


// escapeSpecialChars will escape any special characters in a string to prepare for regular expressions
const escapeSpecialChars = (regex) => {
  return regex.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
};

export const censorInput = (input) => {
  let isDirty = false;
  const escapedProfanity = profanityList.map(word => escapeSpecialChars(word));
  const regex = new RegExp(escapedProfanity.join('|'), 'gi');
  input = input.replace(regex, match => {
    isDirty = true;
    match = match.split('');
    return match.reduce(prev => prev + '*', '');
  });
  if (isDirty) return [true, input];
  return [isDirty, input];
};


export const checkChainId = async (web3, expectedChain, errorSetter, chainSetter, bypass = false) => {
  if (web3) {
    let id = await web3.eth.getChainId();
    if (id === expectedChain || parseInt(id, 16) === expectedChain) {
      errorSetter(false);
      if (/^0x/.test(id)) chainSetter(parseInt(id, 16));
      else chainSetter(id);
    } else if (bypass) {
      return;
    }
    else {
      errorSetter(true);
      await switchChain(web3, expectedChain);
      id = await web3.eth.getChainId();
      if (id === expectedChain || parseInt(id, 16) === expectedChain)
        errorSetter(false);
      else
        errorSetter(true);
      if (/^0x/.test(id)) chainSetter(parseInt(id, 16));
      else chainSetter(id);
    }
  }
};

export const chains = {
  "5": {
    chainId: `0x${Number(5).toString(16)}`,
    chainName: 'Goerli',
    rpcUrls: ["https://goerli.blockpi.network/v1/rpc/public"],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  "137": {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'Polygon',
    rpcUrls: ["https://polygon.llamarpc.com", "https://rpc-mainnet.maticvigil.com"],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  "80001": {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: 'Mumbai',
    rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai-polygonscan.com'],
  }
};

async function switchChain(web3, chain) {
  if (!window.ethereum) return;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(chain) }],
    });
  } catch (err) {
    console.log("ERROR CODE", err.code);
    // if (err.code === USER_REJECTED) return;
    if (err.code === 4902) {
      try {
        console.log("add Chain");
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [chains[chain]],
        });
      } catch (err) {
        // handle "add" error
        console.log(err);
      }
    }
    console.log(err);
  }
}

export const createDateString = (timestamp) => {
  let date = new Date(timestamp * 1000);
  date = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
    .getDate()
    .toString()
    .padStart(2, '0')}/${date.getFullYear()} - ${date.getHours() === 0 ? '12' : date.getHours() % 12
    }:${date.getMinutes()} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;

  return date;
};

export const parseNftAttributes = (nft) => {
  if (!nft || !nft.tokenMetadata.valid) return;
  const { tokenMetadata: metadata } = nft;
  const card = {};
  card.name = metadata.name;
  card.tokenId = nft.tokenTokenId;
  card.description = metadata.description;
  metadata.attributes?.forEach(attr => { card[attr.trait_type.toLowerCase()] = attr.value; });
  return card;
};

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};


export const verifyAddress = (user) => {
  const address = user?.address;
  let isValid = true;
  let errors = [];
  if (!address) {
    isValid = false;
    errors.push("Address is required");
  }
  if (!user?.first_name) {
    isValid = false;
    errors.push("First Name is required");
  }
  if (!user?.last_name) {
    isValid = false;
    errors.push("Last Name is required");
  }
  if (!address.streetAddress) {
    isValid = false;
    errors.push("Street Address is required");
  }
  if (!address.city) {
    isValid = false;
    errors.push("City is required");
  }
  if (!address.state) {
    isValid = false;
    errors.push("State is required");
  }
  if (!address.postalCode) {
    isValid = false;
    errors.push("Postal / Zip Code is required");
  }
  if (!address.country || !address.countryCode) {
    isValid = false;
    errors.push("Country is required");
  }
  return { isValid, errors };
};