import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 110px;
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: ${({ theme }) => theme.borderMuted};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled.img`
  height: 100%;
  width: auto;
  max-width: 90px;
  max-height: 90px;
  border-radius: 50%;
`;

export const Description = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  p {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const Total = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Trash = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  border: ${({ theme }) => theme.borderMuted};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0.5rem 0 1rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    scale: 1.1;
  }

  img {
    width: 22px;
    height: 22px;
  }
`;
