/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button/Button.component';
import {
  CloseBtn,
  Link,
  Nav,
  NavMenuDesktop,
  NavMenuMobile,
  Wrapper,
} from './Navbar.styles';
import UserMenu from '../UserMenu/UserMenu.component';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import DropDownMenu from '../DropDownMenu/DropDownMenu.component';
import StdButton from '../StdButton/StdButton.component';
import links from './links.json';
import { Icon } from '../Icons/BaseIcon';
import CartIndicator from '../CartIndicator/CartIndicator.component';

const Navbar = (props) => {
  const { toggleLoginModal, toggleCheckout } = props;
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [showMobileMenu, showMobileMenuSet] = useState(false);

  // useEffect(() => {
  //   if (location.search) {
  //     let query = location.search.slice(1);
  //     if (query === 'login') {
  //       toggleLoginModal();
  //     }
  //   }
  // }, [location.search]);

  return (
    <Wrapper>
      <Nav>
        <div className='wrapper'>
          <DropDownMenu
            icon={{
              src: '/images/logo.png',
              height: 24,
              width: 80,
              padding: 0,
            }}
            linkData={links.navLogo}
          />
          <div
            className='mobile-toggle'
            onClick={() => showMobileMenuSet(true)}
          >
            <Icon src='/images/hamburger.png' />
          </div>
        </div>
        <NavMenuDesktop>
          <DropDownMenu
            icon={{
              src: '/images/icon--line-arrow.svg',
              height: 15,
              width: 10,
            }}
            text='Collect'
            linkData={links.collect}
          />
          <DropDownMenu
            icon={{
              src: '/images/icon--line-arrow.svg',
              height: 15,
              width: 10,
            }}
            text='Play'
            linkData={links.play}
          />
          <DropDownMenu
            icon={{
              src: '/images/icon--line-arrow.svg',
              height: 15,
              width: 10,
            }}
            text='Learn'
            linkData={links.learn}
          />
          <a href='https://www.locgame.io/blog-news' style={{ height: '100%' }}>
            <Link>NEWS</Link>
          </a>
        </NavMenuDesktop>
        <NavMenuMobile className={showMobileMenu ? 'visible' : ''}>
          <CloseBtn onClick={() => showMobileMenuSet(false)} />
        </NavMenuMobile>
        {!user ? (
          <div className='login-wrapper'>
            <StdButton
              width='100'
              onClick={toggleLoginModal}
              margin='0 7px 0 0'
            >
              LOG IN
            </StdButton>
          </div>
        ) : (
          <div className='wrapper'>
            <CartIndicator onClick={toggleCheckout} />
            <UserMenu
              user={user}
              username={`${user.first_name || ''} ${user.last_name || ''}`}
              avatar={user.avatar}
            />
          </div>
        )}
      </Nav>
    </Wrapper>
  );
};

export default React.memo(Navbar);
