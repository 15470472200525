import styled from 'styled-components';

export const Container = styled.div`
  /* height: 155px; */
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '458px'};
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0.5rem 0;
  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-gutter: 10px;
  scrollbar-color: ${({ theme }) =>
    `${theme.borderMutedColor} ${theme.bgColor}`};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.borderMutedColor};
    border-radius: 5px;
  }
`;

export const Thumbnail = styled.div`
  display: block;
  width: 135px;
  min-width: 135px;
  height: 135px;
  border-radius: 0.5rem;
  background-color: #d9d9d9;
  scroll-snap-align: center;
  overflow: hidden;
  margin-right: 0.75rem;

  &.active {
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
