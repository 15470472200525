/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Thumbnail } from './ProductSlider.styles';

const ProductSlider = (props) => {
  const { thumbs, activeThumb, setActive } = props;
  const handleClick = (e) => {
    const { id } = e.target;
    setActive(parseInt(id));
  };

  return (
    <Container {...props}>
      {thumbs?.length > 0 &&
        thumbs.map((thumb, i) => (
          <Thumbnail
            key={i}
            id={i}
            onClick={handleClick}
            className={activeThumb === i ? 'active' : null}
          >
            <img src={thumb} alt={`Variant ${i + 1}`} id={i} />
          </Thumbnail>
        ))}
    </Container>
  );
};

export default ProductSlider;
