import { createContext, useEffect, useState } from 'react';
import { updateUserById } from '../utils/api';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState({ items: new Map(), count: 0 });

  useEffect(() => {
    if (user?._id) {
      updateUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.internal_wallet, user?.external_wallet, user?.wallet]);

  useEffect(() => {
    if (!user) return;
    const userCart = {
      ...cart,
      items: Array.from(cart?.items.entries()),
      count: cart?.items?.size || 0,
    };
    localStorage.setItem(`${user._id}-cart`, JSON.stringify(userCart));
  }, [cart, user]);

  async function updateUserData() {
    try {
      await updateUserById(user);
    } catch (err) {
      console.log(err?.message || err);
    }
  }
  return (
    <UserContext.Provider value={{ user, setUser, cart, setCart }}>
      {props.children}
    </UserContext.Provider>
  );
};
