import styled from 'styled-components';

const defaultWidth = '180';
const defaultHeight = '40';

export const StyledButton = styled.button`
  ${(props) => {
    const {
      height,
      width,
      minWidth,
      maxWidth,
      margin,
      padding,
      color,
      border,
      disabled,
      theme,
    } = props;
    const common = `
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${
        !height ? `${defaultHeight}px` : !isNaN(height) ? `${height}px` : height
      };
      width: ${
        !width ? `${defaultWidth}px` : !isNaN(width) ? `${width}px` : width
      };
      min-width: ${
        !minWidth ? null : !isNaN(minWidth) ? `${minWidth}px` : minWidth
      };
      max-width: ${
        !maxWidth ? null : !isNaN(maxWidth) ? `${maxWidth}px` : maxWidth
      };
      margin: ${margin && margin};
      padding: ${padding ? padding : '.25rem .75rem'};
      background: ${color ? color : theme.primaryColor};
      border: ${border ? border : '3px solid #6557bd'};
      border-radius: 8px;
      color: ${theme.fontColor};
      font: ${theme.font.stdButton};
      cursor: pointer;
      isolation: isolate;

      &:disabled {
        background: rgba(255, 255, 255, 0.15);
        cursor: default;
      }

      &:hover {
        background: ${disabled ? '' : theme.hoverGradient};
        
      }

      &.pulse{
        animation: 2s ease-in-out infinite  pulse;
      }

      @keyframes pulse {
        0%{
          transform: scale(.9);
        }
        25%{
          transform: scale(1.1, .9)
        }
        50%{
          transform: scale(.9);
        }
        75%{
          transform: scale(.9, 1.1)
        }
        100%{
          transform: scale(.9)
        }
      }
    `;

    if (props.variant === 'buy') {
      return `
        ${common}
        background: linear-gradient(145.25deg, #fdfa3b 0%, #ef7a11 112.64%);
        border: 3px solid #f7780a;
        border-radius: 8px;
        text-transform: uppercase;
        color: black;
        font-weight: 700;
       
        &:hover {
          background: linear-gradient(145.25deg, #ef7a11 0%, #fdfa3b 112.64%);
          border-color: gold;
          filter: brightness(85%);
        }
      `;
    }
    if (props.variant === 'secondary') {
      return `
        ${common}
        background-color: rgba(149, 135, 224, .14);
        border: 3px solid rgba(149, 133, 255, 1);
      `;
    }
    // Standard Button
    return `
      ${common}
    `;
  }}
`;
