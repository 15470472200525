import styled from 'styled-components';

export const Container = styled.div`
  //height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  cursor: pointer;
  position: relative;
`;

export const Menu = styled.div`
  display: flex;
  background-color: #28234a;
  color: #d3cdcd;
  width: max-content;
  min-width: 160px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 70px;
  opacity: 0;
  transform: translateY(-200%);
  transition: all 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  /* @media screen and (max-width: 1199px) {
    left: -30px;
  } */
`;

export const MenuItems = styled.ul`
  list-style: none;
`;

export const Link = styled.li`
  background-color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.fontColor};
  font: ${({ theme }) => theme.font.body};
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  text-transform: uppercase;

  &:hover {
    padding-left: 20px;
    ${({ theme }) => theme.navHighlight};
    background-color: ${({ theme }) => theme.navHoverBg};
    transition: padding-left 0.3s, background-color 0.3s;
  }
`;
export const MenuTitle = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.fontColor};
  font: ${({ theme }) => theme.font.body};
  font-size: 20px;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  text-transform: uppercase;
  &:hover {
    background-color: ${({ theme }) => theme.navHoverBg};
  }
`;
