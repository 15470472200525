import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 60px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.navHeight};
  background: ${({ theme }) => theme.bgColor};

  .row {
    display: flex;
    width: 100%;
    max-width: 436px;
    align-items: center;
    justify-content: center;
    margin: 1rem;
  }

  @media screen and (max-width: 545px) {
    max-width: 350px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1rem;
  gap: 1rem;
`;

export const BorderWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.borderGradient};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  justify-self: flex-start;
  padding: ${({ padding }) => (padding ? padding : '2px')};
  border-radius: 6px;
  overflow: hidden;
  margin: ${({ margin }) => (margin ? margin : null)};
  width: ${({ width }) => (width ? width : null)};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem;

  pre {
    max-width: 98%;
    white-space: pre-wrap;
    margin: 1.5rem 0;
    max-height: 350px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => `${theme.borderMutedColor} black`};
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: black;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.borderMutedColor};
      border-radius: 5px;
    }
  }

  select {
    display: inline-block;
    position: relative;
    width: 100%;
    appearance: none;
    background: ${({ theme }) => theme.bgColor};
    border: ${({ theme }) => theme.borderMuted};
    border-radius: 6px;
    color: ${({ theme }) => theme.fontColor};
    padding: 12px 0 12px 10%;
    font: ${({ theme }) => theme.font.body};
    font-weight: 800;
    outline: none;
    cursor: pointer;
  }
  .size-select {
    position: relative;
    min-width: 150px;
  }
  .size-select::after {
    content: '';
    position: absolute;
    height: 8px;
    width: 11px;
    background-image: url('/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
  }

  option {
    background: ${({ theme }) => theme.bgColor};
  }

  .reward {
    margin: 1rem auto;
    color: #ffd700;

    p {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
    }

    p.points {
      font-size: 2rem;
      font-weight: 600;
      margin: 0.5rem 0 0 0.25rem;

      span {
        text-transform: none;
        font-size: 0.8rem;
        font-weight: 400;
        vertical-align: super;
        margin-left: 0.3rem;
      }
    }
  }

  .back {
    border-bottom: 1px solid ${({ theme }) => theme.fontMuted};
    color: ${({ theme }) => theme.fontMuted};
    position: absolute;
    top: 20px;
    left: 1.25rem;
    cursor: pointer;
  }
`;

export const PriceContainer = styled.div`
  min-width: 130px;
  font-size: 1.5rem;
  font-weight: 600;
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  margin: ${({ margin }) => margin || null};
  position: relative;

  .check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 250px;
    width: 250px;
    z-index: 300;
  }
`;

export const ImageWrapper = styled.div`
  width: 600px;
  height: 600px;
  background: linear-gradient(
    214.96deg,
    #ffffff -1.23%,
    #5a45db 68.63%,
    #4330b5 101.68%
  );
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  padding: 2px;

  .image-container {
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    inset: 2px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: url(https://static-files.locgame.io/products/c3f06e0d-e182-4142-9177-2886ad0b4c4b);
    background-size: cover;
    border-radius: calc(0.5rem - 2px);
  }

  .main-image {
    width: auto;
    height: 90%;
  }
`;
