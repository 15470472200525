import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  ${(props) => (props.isSelected ? `scale: 1;` : `scale: .9;`)}
  background-color: ${(props) => props.color};
  border-radius: 6px;
  border: ${({ theme }) => theme.borderMuted};
  margin: ${({ margin }) => margin || '0 -.5rem 0 0'};
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;
