import './App.css';
import React, { memo, useState, useContext, useEffect, useCallback, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Browser, Route, Switch } from 'react-router-dom';
import { lightTheme, darkTheme, GlobalStyles } from './themes';
import Navbar from './components/Navbar/Navbar.component';
import Footer from './components/Footer/Footer.component';
import useWeb3 from './utils/useWeb3';
import { UserContext } from './context/UserContext';
import CardLibrary from './pages/CardLibrary/CardLibrary';
import { fetchRates, fetchUserByCognitoId } from './utils/api';
import config from './config';
import Amplify, { Auth, Hub } from 'aws-amplify';
import Spinner from './components/Spinner/Spinner';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal.component';
import ScrollToTop from './utils/scrollToTop';
import LandingPage from './pages/LandingPage/LandingPage.component';
import { useAccount, useConnectId } from '@particle-network/connect-react-ui';
import ProductView from './pages/ProductView/ProductView';
import Checkout from './pages/Checkout/Checkout';
import LandingPage2 from './pages/LandingPage/LandingPage2.component';
const OverviewPage = React.lazy(() => import('./pages/OverviewPage/OverviewPage.component'));
const TokenPage = React.lazy(() => import('./pages/TokenPage/TokenPage.component'));
const LorePage = React.lazy(() => import('./pages/LorePage/LorePage.component'));
const ListingPage = React.lazy(() => import('./pages/ListingPage/ListingPage'));
const ItemPage = React.lazy(() => import('./pages/ItemPage/ItemPage'));
const Homepage = React.lazy(() => import('./pages/Homepage/Homepage'));
const SignupPage = React.lazy(() => import('./pages/SignupPage/SignupPage.component'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage/ProfilePage'));
const ProductPage = React.lazy(() => import('./pages/ProductPage/ProductPage'));
const AllItems = React.lazy(() => import('./pages/AllItemsPage/AllItems'));
const SacrificePage = React.lazy(() => import('./pages/SacrificePage/SacrificePage'));
const CollectionPage = React.lazy(() => import('./pages/CollectionPage/CollectionPage'));
const PasswordReset = React.lazy(() => import('./components/PasswordReset/PasswordReset.component'));
const PasswordChange = React.lazy(() => import('./pages/PasswordChange/PasswordChange.component'));
const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage/PrivacyPage'));
const ConfirmContactPage = React.lazy(() => import('./pages/ConfirmContactPage/ConfirmContact'));
const SupportPage = React.lazy(() => import('./pages/Support/SupportPage'));
const EmailVerifyPage = React.lazy(() => import('./pages/EmailVerifyPage/EmailVerify'));

Amplify.configure(config.cognito);


function App() {
  const { web3 } = useWeb3();
  const [theme] = useState('dark');
  const { user, setUser, setCart } =
    useContext(UserContext);
  const [loginModal, setLoginModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [checkout, checkoutSet] = useState(false);
  const [rate, setRate] = useState(null);
  const [maticRate, setMaticRate] = useState(null);
  const account = useAccount();
  const particleId = useConnectId();

  const toggleLoginModal = useCallback(() => {
    setLoginModal(!loginModal);
  }, [loginModal]);

  useEffect(() => {
    if (!account || user === null) return;
    if (
      particleId === 'particle' &&
      user?.internal_wallet !== account?.toLowerCase()
    ) {
      setUser((prevUser) => ({
        ...prevUser,
        internal_wallet: account?.toLowerCase(),
      }));
    } else if (
      particleId !== 'particle' &&
      user?.external_wallet !== account?.toLowerCase()
    ) {
      setUser((prevUser) => ({
        ...prevUser,
        external_wallet: account?.toLowerCase(),
        external_wallet_client: particleId,
      }));
    }
    console.log('Setting current Wallet', account);
    setUser((prevUser) => ({ ...prevUser, wallet: account?.toLowerCase() }));
  }, [account]);


  const getUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const token = cognitoUser.signInUserSession.idToken.jwtToken;
      const roles =
        cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
      localStorage.setItem('token', token);
      const user = await fetchUserByCognitoId(cognitoUser?.attributes?.sub);
      user.avatar = user.avatar || 'user.svg';
      user.roles = roles;
      const cart = localStorage.getItem(`${user._id}-cart`);
      if (cart) {
        const parsedCart = JSON.parse(cart);
        const items = new Map(parsedCart?.items);
        setCart({ ...parsedCart, items, count: items.size });
      }
      setUser(user);
      return user;
    } catch (err) {
      setUser(null);
    }
  }, [setUser, setCart]);

  const signOut = useCallback(async () => {
    window.location.assign(config.cognito.oauth.redirectSignOut);
    setUser(null);
    localStorage.removeItem('token');
  }, [setUser]);

  const fetchRate = useCallback(async () => {
    const rates = await fetchRates();
    setRate(rates.LOCG);
    setMaticRate(rates.MATIC);
    localStorage.setItem('rates', JSON.stringify(rates));
  }, []);

  useEffect(() => {
    let tempUser;

    async function checkRate() {
      let rates = JSON.parse(localStorage.getItem('rates'));
      if (rates?.created) {
        const timestamp = new Date(rates.created);
        let currentDate = new Date();
        if (currentDate.getTime() - timestamp.getTime() > 3600000) {
          fetchRate();
        } else {
          setRate(rates.LOCG);
          setMaticRate(rates.MATIC);
        }
      } else {
        fetchRate();
      }
    }

    checkRate();

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'autoSignIn':
        case 'federatedSignIn':
          tempUser = getUser();
          setLoginModal(false);
          break;
        case 'oAuthSignOut':
        case 'signOut':
          signOut();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log({ level: 'Warning', message: 'Sign in failure', data });
          break;
        default:
          break;
      }
    });
    if (!tempUser) {
      getUser();
    }

  }, [getUser, signOut, fetchRate]);


  const toggleConfirmation = useCallback(() => {
    setConfirmation(!confirmation);
  }, [confirmation]);

  const toggleCheckout = useCallback(() => {
    checkoutSet(!checkout);
  }, [checkout]);

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Browser>
        <ScrollToTop  >
          {/* <div className="topDiv" ref={setRef}></div> */}
          <Navbar
            loginModal={loginModal}
            toggleLoginModal={toggleLoginModal}
            toggleCheckout={toggleCheckout}
            web3={web3}
          // background={visible}
          />
          {checkout && <Checkout sendClose={toggleCheckout} />}
          <Suspense fallback={<Spinner />}>
            {confirmation && <ConfirmationModal sendClose={toggleConfirmation} />}
            {loginModal && <SignupPage sendClose={toggleLoginModal} />}
            <Switch>
              <Route
                path='/support'
                render={(props) => <SupportPage {...props} />}
              />
              <Route
                path='/collection'
                render={(props) => <CollectionPage {...props} />}
              />
              {/* <Route
                path='/signup'
                render={(props) => <SignupPage {...props} />}
              /> */}
              <Route
                path='/verify'
                exact
                render={(props) => <EmailVerifyPage {...props} />}
              />
              <Route
                path='/contact-confirmation'
                render={(props) => <ConfirmContactPage {...props} />}
              />
              <Route
                path='/privacy'
                render={(props) => <PrivacyPage {...props} />}
              />
              <Route
                path='/profile/:tab?'
                exact
                render={(props) =>
                  web3 && (
                    <ProfilePage
                      {...props}
                      web3={web3}
                      rate={rate}
                      maticRate={maticRate}
                    // provider={provider}
                    // setProvider={setProvider} 
                    />
                  )}
              />
              <Route
                path='/overview'
                render={(props) => <OverviewPage {...props} />}
              />
              <Route
                path='/token'
                render={(props) => <TokenPage {...props} />}
              />
              <Route
                path='/lore'
                render={(props) => <LorePage {...props} />}
              />
              <Route
                path='/all-items'
                render={(props) => (
                  <AllItems {...props} maticRate={maticRate} />
                )}
              />
              <Route
                path='/products/:id'
                render={(props) =>
                  web3 && (
                    <ProductPage
                      {...props}
                      // toggleLoginModal={toggleLoginModal}
                      productType='001'
                    />
                  )
                }
              />
              <Route
                path='/merch/:id'
                render={(props) =>

                  <ProductView
                    {...props}
                    // toggleLoginModal={toggleLoginModal}
                    toggleConfirmation={toggleConfirmation}
                  />

                }
              />
              <Route
                path='/list/:id'
                render={(props) =>
                  web3 && (
                    <ListingPage
                      {...props}
                      // toggleLoginModal={toggleLoginModal}
                      toggleConfirmation={toggleConfirmation}
                      rate={rate}
                      web3={web3}
                    />
                  )
                }
              />
              <Route
                path='/sacrifice/:id'
                render={(props) =>
                  web3 && (
                    <SacrificePage
                      {...props}
                      // toggleLoginModal={toggleLoginModal}
                      toggleConfirmation={toggleConfirmation}
                      rate={rate}
                      web3={web3}
                    />
                  )
                }
              />

              <Route
                path='/item/:id'
                render={(props) =>
                  <ItemPage
                    {...props}
                  // toggleLoginModal={toggleLoginModal}
                  />
                }
              />
              <Route
                path='/password-reset'
                render={(props) => <PasswordReset />}
              />
              <Route
                path='/profile/password-change'
                render={(props) => (<PasswordChange user={user} />)}
              />

              <Route
                path='/'
                exact
                render={(props) => (
                  <Homepage
                    {...props}
                    rate={rate}
                    maticRate={maticRate}
                    toggleConfirmation={toggleConfirmation}
                    // toggleLogin={toggleLoginModal}
                    user={user}
                  />
                )}
              />
              {/* <Route
                path='/'
                exact
                render={(props) => (
                  <LandingPage
                    toggleLoginModal={toggleLoginModal}
                    {...props}
                  />
                )}
              /> */}
              <Route
                path='/:tab?'
                exact
                render={(props) => (
                  <Homepage
                    {...props}
                    rate={rate}
                    maticRate={maticRate}
                    toggleConfirmation={toggleConfirmation}
                    // toggleLogin={toggleLoginModal}
                    user={user}
                  />
                )}
              />
            </Switch>
          </Suspense>
          <Footer />
        </ScrollToTop>
      </Browser>
    </ThemeProvider>
  );
}

export default memo(App)


