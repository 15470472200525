import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-top: 1rem;

  .title-block {
    /* font-family: 'Dark twenty'; */
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0.6rem;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background: linear-gradient(to bottom, #ef7a11 0%, #fdfa3b 112.64%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.small {
      font-size: 1rem;
      font-weight: 300;
    }

    @media screen and (max-width: 725px) {
      font-size: 1rem;
      &.small {
        font-size: 0.8rem;
      }
    }
  }
`;

export const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 250px;
  margin: 0 1rem;
  scroll-snap-align: center;

  img {
    width: 225px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
    font-weight: 500;
  }
  @media screen and (max-width: 1680px) {
    margin: 0 0.5rem;
    max-width: 165px;
    img {
      max-width: 165px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: 2rem auto 1rem;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory; /* Enable horizontal snap scrolling */
  scroll-behavior: smooth; /* Add smooth scrolling effect */
  // scroll bar
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) =>
    `${theme.borderMutedColor} ${theme.bgColor}`};
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.bgColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.borderMutedColor};
    border-radius: 5px;
  }

  @media screen and (max-width: 565px) {
    width: 95%;
    padding: 0 45px;
  }
`;
