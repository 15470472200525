import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  body: '#fff',
  fontColor: '#000',
  navHeight: '100px',
};

export const darkTheme = {
  body: '#000',
  fontColor: '#fff',
  fontMuted: 'rgba(255,255,255,.7)',
  borderMutedColor: ` hsl(250, 8%, 16%)`,
  bgColor: `rgba(17,15,28,1)`,
  lightBgColor: '#1D1B27',
  // lightBgColorRGBA: 'rgba(36, 48, 117,.95)',rgb(25, 55, 135)
  lightBgColorRGBA: 'rgba(22, 56, 140,.92)',
  primaryColor: '#362e55',
  secondaryColor: '#271A3E',
  purple: `#954af3`,
  lightPurple: `rgba(149, 133, 255, 1)`,
  lightBlue: '#667FDF',
  red: '#F01F9C',
  errorColor: 'rgb(255,65,65)',
  errorBackground: 'rgba(255,65,65,0.1)',
  errorBorder: `1px solid rgb(255,65,65)`,
  inputActiveColor: 'rgba(255, 255, 255, 1)',
  inputActiveBorder: '1px solid rgba(255, 255, 255, 1)',
  inputActiveBackground: 'rgba(255, 255, 255, 0.1)',
  inputDefaultColor: 'rgba(255, 255, 255, 0.4)',
  inputDefaultBorder: '1px solid rgba(255, 255, 255, 0.4)',
  inputDefaultBackground: 'none',
  inputFontColor: '#fff',
  successColor: '#2abd57',
  navHighlight: 'border-left: 2px solid #964af4',
  navBorder: 'hsl(0,0%,100%,.15)',
  navHoverBg: 'rgba(255,255,255,.1)',
  navHeight: '70px',
  footerColor: '#211F2E',
  borderGradient: `linear-gradient(
    102.03deg,
    #ffffff -15.87%,
    #5a45db 40.43%,
    #4330b5 113.65%
  )`,
  mainGradient: `linear-gradient(
    287.38deg,
    #4a7af3 -1.9%,
    #5140be 43.64%,
    #954af3 83.54%
  );`,
  secondaryGradient: `linear-gradient(145.67deg, #7C9CEE -4.63%, #465BCA 138.99%)`,
  linearGradient: ` linear-gradient(169.43deg, #674A8C -0.5%, #2E1F49 83.37%)`,
  redGradient: ` linear-gradient(315.87deg, #F01F9C 9.19%, #FF4F4F 106.45%);`,
  cardHoverGradient: `linear-gradient(180deg, #f01f9c 0%, #000000 100%);`,
  hoverGradient: `linear-gradient(
    285.09deg,
    #14317C -0.33%,
    #6C4DC4 55.44%,
     #34094F 104.31%)`,
  packBorder: `linear-gradient(94.06deg, #00FFC2 12.09%, #86D3FF 101.07%, #83D4FE 101.07%)`,
  greenGradient: `linear-gradient(105.36deg, #1DE2EF 18.83%, #00B666 99.87%);`,
  dropShadow: `drop-shadow(5px 5px 1px rgba(50, 66, 206, 0.6))
               drop-shadow(2px 8px 2px rgba(56, 73, 224, 0.4))
               drop-shadow(5px 12px 20px rgba(56, 73, 224, 0.4));`,
  boxShadow: `5px 5px 1px rgba(50, 66, 206, 0.6),
  2px 8px 2px rgba(56, 73, 224, 0.4), 5px 12px 20px rgba(56, 73, 224, 0.4);`,
  btnGlow: `16px 19px 20px rgba(50, 66, 206, .67);`,
  //buttonClipPath: `polygon(85% 0, 100% 20%, 100% 100%, 15% 100%, 0 80%, 0% 0%)`,
  cardClipPath: `-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 56% 98%, 44% 98%, 40% 100%, 0 100%);
                  clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 56% 98%, 44% 98%, 40% 100%, 0 100%);`,
  buttonRadius: `0 6em / 0 6em`,
  borderMuted: `2px solid hsl(250, 8%, 16%)`,
  productHover: `box-shadow: 0px 7px 70px 0px rgb(155, 121, 252);
                 background-color: rgb(155, 121, 252, 0.6);
                 zoom: 102%;
                 `,
  cardHover: `filter: drop-shadow(0px 1px 50px rgb(149, 74, 243,.8));`,
  font: {
    body: '300 1rem/1.125rem TTHoves',
    button: '600 1.25rem/1.375rem TTHoves',
    stdButton: '600 1rem/1rem TTHoves',
    small: '600 .75rem/.8rem TTHoves',
    big: '700 1.875rem/2rem TTHoves',
    footer: '400 14px/19px TTHoves',
    footerHeading: '700 20px/26px TTHoves',
  },
  scrollBar: `  scrollbar-width: thin;
  scrollbar-color: #daed08 ${({ theme }) => theme.lightBgColorRGBA};
  scrollbar-gutter: stable;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${({ theme }) => theme.lightBgColorRGBA};
    border-radius: 5px;
  }
  /* &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.lightBgColorRGBA};
  } */
  &::-webkit-scrollbar-thumb {
    background-color: #daed08;
    border-radius: 5px;
  }`
};

export const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Thin.woff');
  font-weight:100;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-ExtraLight.woff');
  font-weight:200;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Light.woff');
  font-weight:300;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Regular.woff');
  font-weight:400;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Medium.woff');
  font-weight:500;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-DemiBold.woff');
    font-weight: 600;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-Bold.woff');
    font-weight: 700;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-ExtraBold.woff');
    font-weight: 800;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-Black.woff');
    font-weight: 900;
}
@font-face {
    font-family: 'Dark twenty';
    src: url('/fonts/Dark-Twenty.otf');
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/ProximaNova-Regular.otf');
}


body {
  width: 100%;
  background: ${({ theme }) => theme.bgColor};
  background-repeat:no-repeat;
  background-size:cover;
  font-family: 'TTHoves', sans-serif;
  color: ${(props) => props.theme.fontColor};  
  -webkit-font-smoothing: antialiased;
  // scroll bar
  scrollbar-width: none;
  scrollbar-color: ${({ theme }) =>
    `${theme.borderMutedColor} black`};
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.borderMutedColor};
    border-radius: 5px;
  }
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  
}


  #root{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
}

/* #portal{
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1000;
} */

a{
  text-decoration:none;   
  color: ${({ theme }) => theme.fontColor};
}

  
h1{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 52px;
}
h2{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 40px;
}
h3{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 24px;
}
h4{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 18px;
}
h5{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 16px;
}
h6{
  font-family: 'TTHoves';
  font-weight:700;
  font-size: 14px;
}

ul{
  list-style:none;
}

.particle-connect-ui, .particle-connect-ui-theme-dark{
  width: 100% !important;
}

/* input, textarea {
  height: 45px;
  width: 300px;
  border: ${({ theme }) => theme.inputDefaultBorder};
  background: none;
  color: ${({ theme }) => theme.inputDefaultColor};
  padding: 0.5rem 1rem;
  margin: 0.75rem 0;
  font-size: 1rem;
  font-family: 'TTHoves';

  &:hover {
    border:  ${({ theme }) => theme.inputActiveBorder};
  }

  &:focus-visible {
    border:  ${({ theme }) => theme.inputActiveBorder};
    //background-color: ${({ theme }) => theme.inputActiveBackground};
    outline: none;
    color:${({ theme }) => theme.inputActiveColor};
  }

  &.error {
    color: ${({ theme }) => theme.inputActiveColor};
    outline: none;
    border: ${({ theme }) => theme.errorBorder};
    background: ${({ theme }) => theme.errorBackground}
  }
} */


`;
