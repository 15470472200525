import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Count, Limit } from './Counter.styles';

const Counter = ({ qty, qtySet, max, showMax }) => {
  const handleClick = (e) => {
    e.preventDefault();
    let id = e.target.id || e.target.parentElement.id;
    if (max !== undefined) {
      if (id === 'subtract') {
        qtySet((prev) => (prev > 1 ? prev - 1 : 1));
      } else if (id === 'add') {
        qtySet((prev) => (prev < max ? prev + 1 : prev));
      }
    } else {
      if (id === 'subtract') {
        qtySet((prev) => (prev > 1 ? prev - 1 : 1));
      } else if (id === 'add') {
        qtySet((prev) => prev + 1);
      }
    }
  };

  return (
    <Container>
      <Button id='subtract' onClick={handleClick}>
        <img src='/images/icon--minus-black.svg' alt='Subtract' />
      </Button>
      <Count>
        <p className='counter-text'>{qty}</p>
      </Count>
      <Button id='add' onClick={handleClick}>
        <img src='/images/icon--plus-black.svg' alt='Add' />
      </Button>
      {showMax && max && <Limit>Limit: {max}</Limit>}
    </Container>
  );
};

export default Counter;
