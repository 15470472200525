import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  width: 100vw;
  min-height: 101vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  backdrop-filter: blur(4px);
`;

export const Modal = styled.div`
  display: flex;
  position: relative;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #362e55;
  background-size: cover;
  width: 410px;
  height: auto;
  overflow: none;
  color: ${({ theme }) => theme.fontColor};
  border-radius: 8px;

  h3 {
    margin-bottom: 1.5rem;
    &.action {
      margin: 1.25rem 0 0;
    }
  }
  h4 {
    margin-bottom: 0.7rem;
  }
`;

export const CloseBtn = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13px;
  right: 17px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;
