import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* padding: 0 0.5rem; */
  cursor: pointer;
  position: relative;
`;

export const Username = styled.p`
  display: inline;
  font: ${({ theme }) => theme.font.button};
  margin: 0 0.5rem;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgColor};
  color: #d3cdcd;
  width: 160px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: -81px;
  top: 65px;
  opacity: 0;
  transform: translateY(-200%);
  transition: all 0.5s ease-in-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
`;

export const Link = styled.li`
  background-color: inherit;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.fontColor};
  font: ${({ theme }) => theme.font.body};
  font-weight: 400;
  padding: 0.5rem 0.8rem;

  &:hover {
    padding-left: 20px;
    ${({ theme }) => theme.navHighlight};
    background-color: ${({ theme }) => theme.navHoverBg};
    transition: padding-left 0.3s, background-color 0.3s;
  }
`;

export const Wallet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;
  width: 25px;
  z-index: 10;
  transform: translate(60%, -40%);

  img {
    height: auto;
    width: 100%;
    cursor: pointer;
  }
`;
