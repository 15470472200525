import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { UserContext } from '../../context/UserContext';
import {
  Container,
  Wrapper,
  ItemList,
  Controls,
  Details,
  CloseBtn,
} from './Checkout.styles';
import CheckoutItem from '../../components/CheckoutItem/CheckoutItem.component';
import StdButton from '../../components/StdButton/StdButton.component';
import StyledInput from '../../components/Input/Input.component';
import Slider from '../../components/Input/Slider.component';
import {
  deleteOrder,
  fetchCurrencies,
  fetchRates,
  getDiscount,
  getGasPrice,
  getMaxPriorityFee,
  updateOrder,
} from '../../utils/api';
import {
  USER_REJECTED,
  INSUFFICIENT_FUNDS,
  INVALID_OPCODE,
  SLOW_TX,
} from '../../constants';
import config from '../../config';
import { HandleOutsideClick, verifyAddress } from '../../utils/utils';
import { createCryptoOrder, createFiatOrder } from '../../utils/api';
import ABI from 'erc-20-abi';
import useWeb3 from '../../utils/useWeb3';
import ErrorsModal from '../../components/ErrorsModal/ErrorsModal.component';

const Checkout = ({ sendClose }) => {
  const {
    lcConversionRate,
    recv_acct,
    LOCGcontract,
    USDTcontract,
    ethChainId,
  } = config;
  const { user, cart, setCart } = useContext(UserContext);
  const [items, itemsSet] = useState([]);
  const [discountCode, setDiscountCode] = useState('');
  const [discount, setDiscount] = useState(undefined);
  const [rewardPoints, setRewardPoints] = useState(0);
  const [currency, setCurrency] = useState('USDT');
  const [currencies, setCurrencies] = useState([]);
  const { web3, account, checkChainId } = useWeb3();
  const { ref, isComponentVisible } = HandleOutsideClick();
  const [txError, txErrorSet] = useState({ state: false, msg: '' });
  const [error, errorSet] = useState({ title: '', errors: [] });
  let submittedOrder;

  useEffect(() => {
    if (cart?.discount?.active) {
      setDiscountCode(cart.discount.code);
    }
    if (cart?.pointsUsed > 0) {
      setRewardPoints(cart.pointsUsed);
    }
  }, []);

  useEffect(
    (e) => {
      if (!isComponentVisible) {
        sendClose(e);
      }
    },
    [isComponentVisible, sendClose]
  );
  const handleTxError = async (err, order) => {
    console.log(err);
    console.log('Order In Err', order);
    if (err.code === USER_REJECTED) {
      await deleteOrder(order);
      return;
    }
    if (err.code === INVALID_OPCODE) {
      await deleteOrder(order);
      console.log('Invalid Wallet, possible insufficient funds.', order);
      return;
    }
    if (err?.message.includes(INSUFFICIENT_FUNDS)) {
      await deleteOrder(order);
      txErrorSet({ state: true, msg: 'Insufficient Funds' });
      setTimeout(() => {
        txErrorSet({ state: false, msg: '' });
      }, 3000);
      console.log('Insufficient Funds', order);
      return;
    }
    if (err?.message.includes(SLOW_TX)) {
      console.log('Chain is slow, not Mined in 50 blocks');
      return;
    }
    if (order?._id) {
      await deleteOrder(order);
    }
  };

  async function updateCart({
    item,
    count,
    newCart = { ...cart },
    discount,
    pointsUsed = rewardPoints,
  } = {}) {
    if (!user || !user.address) return;
    let items = [];
    if (!isNaN(count) && item) {
      if (count === 0) {
        newCart.items.delete(item);
      } else {
        const updatedItem = { ...newCart.items.get(item) };
        updatedItem.qty = count;
        newCart.items.set(item, updatedItem);
      }
      newCart.count = newCart.items.size;
    }

    if (discount) {
      newCart.discount = discount;
      newCart.discountAmount = 0;
    } else {
      discount = newCart?.discount;
      newCart.discountAmount = 0;
    }
    if (pointsUsed >= 0) {
      newCart.pointsUsed = pointsUsed;
      newCart.pointsUsedValue = 0;
    } else {
      pointsUsed = newCart.pointsUsed;
      newCart.pointsUsedValue = 0;
    }
    const itemsArray = Array.from(newCart.items.entries());
    if (itemsArray.length > 0) {
      items = itemsArray.map(([key, item]) => item);
    }
    newCart.subtotal = itemsArray.reduce((acc, [key, item]) => {
      return acc + item.qty * item.price;
    }, 0);
    const {
      discountTotal,
      discountableTotal,
      shippingRequired,
      hasDigitalGoods,
    } = processItems({
      items,
      discount,
    });
    newCart.hasDigitalGoods = hasDigitalGoods;
    newCart.discountAmount =
      discountTotal <= discountableTotal ? discountTotal : discountableTotal;
    newCart.shipping = shippingRequired ? 9.99 : 0;
    if (pointsUsed > 0) {
      newCart.pointsUsedValue = pointsUsed / lcConversionRate;
    } else {
      newCart.pointsUsedValue = 0;
    }
    let total =
      newCart.subtotal - newCart.discountAmount - newCart.pointsUsedValue;
    if (total < 0) {
      if (newCart.pointsUsedValue > 0) {
        let pointsToRemove = Math.ceil(Math.abs(total)) * lcConversionRate;
        setRewardPoints(pointsUsed - pointsToRemove);
        return;
      }
    }
    newCart.total = newCart.items.size > 0 ? total + newCart.shipping : total;
    await calculateExchange(newCart.total);
    setCart(newCart);
  }

  function processItems({ items, discount } = {}) {
    let discountTotal = 0; // Total discount amount
    let discountableTotal = 0; // Total of Items where discount is applicable
    let shippingRequired = false;
    let hasDigitalGoods = false;
    if (items.length === 0)
      return { discountTotal, discountableTotal, shippingRequired };
    for (const item of items) {
      let { price, qty, productType } = item;
      let discountAmount = 0;
      switch (discount?.type) {
        case 'percent':
          if (productType === '001' && discount?.isDigital) {
            discountAmount = price * qty * discount?.value;
            discountTotal += discountAmount;
            discountableTotal += price * qty;
          } else if (productType === '100' && discount?.isPhysical) {
            discountAmount = price * qty * discount?.value;
            discountTotal += discountAmount;
            discountableTotal += price * qty;
          }
          break;
        case 'dollar':
          if (productType === '001' && discount?.isDigital) {
            discountableTotal += price * qty;
          } else if (productType === '100' && discount?.isPhysical) {
            discountableTotal += price * qty;
          }
          discountTotal = discount?.value;
          break;
        default:
          discountTotal += 0;
          break;
      }
      if (productType === '100') {
        shippingRequired = true;
      }
      if (productType === '001' || productType === '004') {
        hasDigitalGoods = true;
      }
    }

    return {
      discountTotal,
      discountableTotal,
      shippingRequired,
      hasDigitalGoods,
    };
  }

  const calculateExchange = async (total) => {
    try {
      const rates = await fetchRates();
      const currencyList = await fetchCurrencies();
      const currencies = currencyList.map((currency) => {
        switch (currency) {
          case 'USDT':
            return {
              currency,
              rate: rates[currency],
              value: (total * rates[currency]).toFixed(2),
            };
          case 'ETH':
            return {
              currency,
              rate: rates[currency],
              value: (total * rates[currency]).toFixed(6),
            };

          case 'LOCG':
            return {
              currency,
              rate: rates[currency],
              value: Math.ceil(total * rates[currency]),
            };

          default:
            return {};
        }
      });

      setCurrencies(
        currencies.filter((currency) => currency.hasOwnProperty('currency'))
      );
    } catch (err) {
      console.log(err);
    }
  };

  // Updates CheckoutItem components
  useEffect(() => {
    if (cart?.items?.size > 0) {
      const itemsArray = Array.from(cart.items.entries());
      itemsSet(itemsArray);
    } else {
      itemsSet([]);
    }
  }, [cart]);
  // Fetch Discount data by Code
  useEffect(() => {
    if (!discountCode) return;
    const fetchDiscount = setTimeout(() => {
      getDiscount(discountCode)
        .then((res) => {
          setDiscount(res);
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            return;
          }
          console.log(err?.response?.data?.message || err?.message || err);
        });
    }, 750);
    return () => {
      clearTimeout(fetchDiscount);
    };
  }, [discountCode]);
  //Apply Reward Points
  useEffect(() => {
    const applyRewardPoints = setTimeout(() => {
      if (rewardPoints >= 0) {
        //TODO apply points
        updateCart({ pointsUsed: rewardPoints });
      }
    }, 50);
    return () => {
      clearTimeout(applyRewardPoints);
    };
  }, [rewardPoints]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case 'rewardPoints':
        if (typeof value === 'string') {
          value = parseInt(value);
        }
        if (value === 0) {
          setRewardPoints(value);
          break;
        } else if (value % 1000 !== 0) {
          const diff = value % 1000;
          value = value - diff;
        }
        setRewardPoints(value);
        break;
      case 'currency':
        setCurrency(value);
        break;
      default:
        break;
    }
  };

  const handleDiscount = () => {
    if (discountCode !== '') {
      updateCart({ discount });
      return;
    }
    setDiscount({});
    updateCart({ discount: {} });
  };
  useEffect(() => {
    console.log('UseEffect', submittedOrder);
  }, [submittedOrder]);

  const submitOrder = async (e) => {
    e.preventDefault();
    if (cart.items.size === 0 || !user || !user.address) {
      return;
    }
    const { isValid, errors } = verifyAddress(user);
    if (!isValid) {
      console.log(errors);
      errorSet({
        title: 'Address Error',
        errors,
        action: 'Please update your profile.',
      });
      return;
    }
    if (cart.hasDigitalGoods && !account) {
      errorSet({
        title: 'Wallet Error',
        errors: ['Please connect a wallet to your profile.'],
      });
    }

    const { id: paymentType } = e.target;
    let order;
    let url;
    switch (paymentType) {
      case 'fiat':
        url = await createFiatOrder(user, cart);
        console.log(url?.href);
        setCart({
          items: new Map(),
          count: 0,
          discount: {},
          pointsUsed: 0,
        });
        window.location.href = url?.href;
        break;
      case 'crypto':
        console.log('crypto', account);
        if (!account) {
          errorSet({
            title: 'Wallet Error',
            errors: ['Please connect a wallet to your profile.'],
          });
          return;
        }
        order = await createCryptoOrder(user, cart, currency);
        console.log('Order', order);
        submittedOrder = order;
        let rates = await fetchRates();
        await checkChainId(ethChainId);
        let rate = rates[currency];
        let total = cart.total * rate;
        let contract;
        switch (currency) {
          case 'USDT':
            total = total.toFixed(2);
            total = web3.utils.toWei(total.toString(), 'mwei');
            contract = new web3.eth.Contract(ABI, USDTcontract);
            try {
              const gas = await contract.methods
                .transfer(recv_acct, total.toString())
                .estimateGas({ from: user?.wallet });
              const gasInGwei = await getGasPrice('ethereum');
              const VALUE_FROM_GAS_STATION = web3.utils.toWei(
                gasInGwei.toString(),
                'gwei'
              );
              const maxPriorityFeePerGas = await getMaxPriorityFee('ethereum');
              await contract.methods
                .transfer(recv_acct, total.toString())
                .send({
                  from: user?.wallet,
                  maxFeePerGas: VALUE_FROM_GAS_STATION,
                  maxPriorityFeePerGas,
                  gas,
                })
                .on('transactionHash', async (txHash) => {
                  order.txHash = txHash;
                  await updateOrder(order);
                });
            } catch (err) {
              handleTxError(err, submittedOrder);
            }
            break;
          case 'ETH':
            total = total.toFixed(18);
            total = web3.utils.toWei(total.toString(), 'ether');
            break;
          case 'LOCG':
            total = Math.ceil(total);
            total = web3.utils.toWei(total.toString(), 'ether');
            contract = new web3.eth.Contract(ABI, LOCGcontract);
            try {
              const gas = await contract.methods
                .transfer(recv_acct, total.toString())
                .estimateGas({ from: user?.wallet });
              const gasInGwei = await getGasPrice('ethereum');
              const VALUE_FROM_GAS_STATION = web3.utils.toWei(
                gasInGwei.toString(),
                'gwei'
              );
              const maxPriorityFeePerGas = await getMaxPriorityFee('ethereum');
              await contract.methods
                .transfer(recv_acct, total.toString())
                .send({
                  from: user?.wallet,
                  maxFeePerGas: VALUE_FROM_GAS_STATION,
                  maxPriorityFeePerGas,
                  gas,
                })
                .on('transactionHash', async (txHash) => {
                  let { nonce } = await web3.eth.getTransaction(txHash);
                  order.txHash = txHash;
                  order.nonce = nonce;
                  const newOrderInfo = await updateOrder(order);
                  console.log(newOrderInfo);
                });
            } catch (err) {
              handleTxError(err, submittedOrder);
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  return ReactDOM.createPortal(
    <Wrapper>
      {error.errors?.length > 0 && (
        <ErrorsModal
          title={error.title}
          errors={error.errors}
          action={error.action}
          sendClose={() => errorSet(null)}
        />
      )}
      <Container ref={ref}>
        <CloseBtn onClick={sendClose} />
        <h3>Checkout</h3>
        <ItemList>
          {items.map(([key, item]) => (
            <CheckoutItem
              key={key}
              cart={cart}
              item={item}
              update={updateCart}
            />
          ))}
        </ItemList>
        <Controls>
          <div className='row'>
            <StyledInput
              width='45%'
              height='45px'
              id='discountCode'
              name='discountCode'
              value={discountCode}
              placeholder='Promo Code'
              type='text'
              onChange={(e) => {
                setDiscountCode(e.target.value);
              }}
              // errors={discount?.error}
            />
            <StdButton
              width='45%'
              style={{
                fontWeight: '700',
                fontSize: '16px',
              }}
              margin='0 0 0 1rem'
              onClick={handleDiscount}
            >
              APPLY DISCOUNT
            </StdButton>
          </div>
          <div className='row'>
            <Slider
              margin='0 1rem 0 0'
              id='rewardPointsSlider'
              name='rewardPoints'
              min={0}
              max={user?.resources?.['1']}
              step={1000}
              onChange={handleChange}
              value={rewardPoints}
            />
            <StyledInput
              style={{ fontWeight: '600', padding: '0 1rem' }}
              width='125px'
              height='35px'
              id='rewardPointsText'
              name='rewardPointsText'
              type='text'
              value={rewardPoints}
              readOnly={true}
            />
          </div>
          <div className='row'>
            <div>
              <div className='select'>
                <select
                  width='50%'
                  name='currency'
                  onChange={handleChange}
                  value={currency}
                >
                  {currencies?.length > 0 &&
                    currencies.map(({ currency: cur, rate, value }) => (
                      <option key={cur} value={cur}>
                        {cur} - {value}
                      </option>
                    ))}
                </select>
              </div>
              {/* <p className='calculate'>Update Cart</p> */}
            </div>
            <Details>
              <h6>
                Subtotal: &nbsp;{' '}
                <span className='total'>
                  $ {cart?.subtotal?.toFixed(2) || 0}
                </span>
              </h6>
              <h6>
                Discount: &nbsp;{' '}
                <span className='total'>
                  {cart?.discountAmount > 0
                    ? `- ${cart?.discountAmount?.toFixed(2)}`
                    : '-'}
                </span>
              </h6>
              <h6>
                Points: &nbsp;{' '}
                <span className='total'>
                  {`${cart?.pointsUsed || ''} ${
                    cart?.pointsUsedValue ? `(-$${cart.pointsUsedValue})` : '-'
                  } `}
                </span>
              </h6>
              <h6>
                Shipping: &nbsp;{' '}
                <span className='total'>
                  {cart?.count > 0 ? `${cart?.shipping?.toFixed(2)}` : '-'}
                </span>
              </h6>
              {/* <h6>
                Tax: &nbsp;{' '}
                <span className='total'>
                  {cart?.tax > 0 ? `- ${cart?.tax?.toFixed(2)}` : '-'}
                </span>
              </h6> */}
              <h6>
                TOTAL: &nbsp;{' '}
                <span className='total'>$ {cart?.total?.toFixed(2) || 0}</span>
              </h6>
            </Details>
          </div>

          <StdButton
            variant='primary'
            margin='1rem auto'
            width='90%'
            id='fiat'
            onClick={submitOrder}
          >
            {txError.state ? txError.msg : 'Buy with Credit Card'}
          </StdButton>
          <StdButton
            variant='buy'
            margin='1rem auto'
            width='90%'
            id='crypto'
            onClick={submitOrder}
          >
            {txError.state ? txError.msg : 'Buy with Crypto'}
          </StdButton>
        </Controls>
      </Container>
    </Wrapper>,
    document.getElementById('portal')
  );
};

export default Checkout;
