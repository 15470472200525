import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserProvider } from './context/UserContext';
import ReactGA from 'react-ga4';
import { ModalProvider } from '@particle-network/connect-react-ui';
import { evmWallets } from '@particle-network/connect';
import { WalletEntryPosition } from '@particle-network/auth';
import { Ethereum, EthereumGoerli, Polygon, PolygonMumbai } from '@particle-network/common';

ReactGA.initialize('G-HPLFQH745T');
window.gtag('config', 'GTM-NCNXVG8');
window.gtag('config', 'AW-10896502630');

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider
      className='particle'
      options={{
        projectId: 'b1561f2f-b68d-4061-aeeb-6a96bed036c4',
        clientKey: 'cCffMaWI5ja61B2Q7QNoScXfEFtTkAtKXXj6lTEO',
        appId: '2e1efd0d-d3ad-4362-a547-901f9d4cb27a',
        chains: [
          Ethereum,
          EthereumGoerli
        ],
        particleWalletEntry: {    //optional: particle wallet config
          displayWalletEntry: false, //display wallet button when connect particle success.
          defaultWalletEntryPosition: WalletEntryPosition.BR,
          supportChains: [
            Ethereum,
            EthereumGoerli,
            Polygon,
            PolygonMumbai
          ],
          // customStyle: {}, //optional: custom wallet style
        },
        wallets: evmWallets({ qrcode: false }),
      }}
      theme={'dark'}
      language={'en'}   //optional：localize, default en
      walletSort={["Wallet"]} //optional：wallet order
      particleAuthSort={[    //optional：display particle auth items and order
        'jwt',
      ]}>
      <UserProvider>
        <App />
      </UserProvider>
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
