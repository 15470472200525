import styled from 'styled-components';

export const Container = styled.div`
  height: 80px;
  width: 230px;
  display: flex;
  /* padding: 5px;
  margin-bottom: 1rem; */
  align-items: center;
  justify-content: flex-end;
  position: relative;
  /* background: rgba(41, 37, 62, 0.85);
  border-radius: 1rem; */
  @media screen and (max-width: 565px) {
    width: 150px;
  }
`;

export const Image = styled.img`
  max-width: 25px;
  max-height: 25px;
  position: absolute;
  top: 10px;
  left: -3px;
  @media screen and (max-width: 565px) {
    width: 18px;
    height: 18px;
  }
`;

export const Text = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  width: 190px;
  @media screen and (max-width: 565px) {
    width: 125px;
    font-size: 0.7rem;
    font-weight: 500;
  }
`;
