import { Auth, API } from 'aws-amplify';
import Web3 from 'web3';
import config from '../config';
import { toChecksumAddress } from './useWeb3';

// ======================== OpenMarket through Backend API ============================
//OpenMarket calls
export const fetchNFT = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}/${contractAddress}/${tokenId}`;

  return await API.get(apiName, path);
};
export const fetchNFTHistory = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/trades/${chain}/${contractAddress}/${tokenId}`;

  return await API.get(apiName, path);
};

export const fetchAllListedNFTs = async (
  { chain = config.maticNetworkName,
    contractAddress = config.nftAddress,
    from = 0,
    limit = 10,
    hasOrder = true,
    onlyValidMetadata = true,
    orderBy,
    priceFrom,
    priceTo,
    erc20PaymentToken } = {}
) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}`;
  if (orderBy === 'newest')
    orderBy = 'OLDEST_ORDERS';
  const options = {
    queryStringParameters: {
      limit,
      from,
      hasOrder,
      contractAddress,
      onlyValidMetadata,
      orderBy,
      priceFrom,
      priceTo,
      erc20PaymentToken
    },
  };
  return await API.get(apiName, path, options);
};

export const fetchUsersListedNFTs = async (
  tokenOwner,
  chain = config.maticNetworkName,
  contractAddress = config.nftAddress,
  hasOrder = true
) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/tokens/${chain}`;
  const options = {
    queryStringParameters: {
      tokenOwner,
      hasOrder,
      contractAddress
    },
  };

  return await API.get(apiName, path, options);
};

export const postSignedOrder = async (chain, order) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/orders/${chain}`;
  const myInit = {
    body: order,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};

export const updateTokenInfo = async (chain, contractAddress, tokenId) => {
  const apiName = 'openMarketplace';
  const path = `/openmarket/commands/update-token/${chain}/${contractAddress}/${tokenId}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.post(apiName, path, myInit);
};


export const addToken = async (chain, contractAddress, tokenId) => {

  const apiName = 'openMarketplace';
  const path = `/openmarket/admin/addtoken/${chain}/${contractAddress}/${tokenId}`;
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {},
  };
  return await API.post(apiName, path, myInit);
};

// ======================== Marketplace Backend API ============================

// Enum

// Order Calls

export async function fetchOrders(userId) {
  let limit = 20;
  let skip = 0;
  let orders = [];
  const apiName = 'marketplace';
  const path = `/order/user/${userId}`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      skip,
    },
  };
  try {
    let res = await API.get(apiName, path, params);
    orders = [...res.data.data];
    while (res.data.data.length > 0) {
      params.queryStringParameters.skip = res.data.skip + limit;
      res = await API.get(apiName, path, params);
      orders = [...orders, ...res.data.data];
    }
  } catch (err) {
    console.log(err);
  }
  return orders;
}

export const fetchUnopenedPacks = async (userId) => {
  console.log("Fetching Unopened Packs");
  let limit = 20;
  let skip = 0;

  let orders = [];
  const apiName = 'marketplace';
  const path = `/order/user/${userId}`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      skip,
      unopened: true
    },
  };
  try {
    let res = await API.get(apiName, path, params);
    orders = [...res.data.data];
    while (res.data.data.length > 0) {
      params.queryStringParameters.skip = res.data.skip + limit;
      res = await API.get(apiName, path, params);
      orders = [...orders, ...res.data.data];
      console.log("Orders", orders);
    }
  } catch (err) {
    console.log(err);
  }

  const unopenedPacks = {};
  orders.forEach(order => {
    order.items.forEach(item => {
      if ((item.productType === '001' || item.productType === '004') && !item.isOpened) {
        unopenedPacks[order._id] = unopenedPacks[order._id] ? [...unopenedPacks[order._id], item] : [item];
      }
    });
  });
  console.log("Unopened Packs", unopenedPacks);
  return unopenedPacks;
};

export async function createOrder(user, cart, paymentType, currency = 'USD') {
  let items = Array.from(cart.items.entries()).map(([key, value]) => ({ ...value }));
  const newCart = { ...cart, items: items };
  const apiName = 'marketplace';
  const path = `/order`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      user,
      cart: newCart,
      paymentType,
      currency
    },
  };
  try {
    const res = await API.post(apiName, path, params);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function updateOrder(order) {
  const apiName = 'marketplace';
  const path = `/order/${order._id}`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      order
    },
  };
  try {
    const res = await API.put(apiName, path, params);
    return res.data;
  } catch (err) {
    console.log(err);
  }
}


export async function deleteOrder(order) {
  console.log("Deleting Order", order._id, order);
  const apiName = 'marketplace';
  const path = `/order/${order?._id}`;
  const params = {
    response: true,
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  try {
    const res = await API.del(apiName, path, params);
    return res.data;
  } catch (err) {
    console.log(err);
  }
}


//Create Paypal Order

export const createFiatOrder = async (user, cart) => {
  try {
    const order = await createOrder(user, cart, 'FIAT');
    console.log("Order - Fiat", order);
    const url = await createPayment(order);
    console.log("URL", url);
    return url;
  } catch (err) {
    console.log(err);
  }
  // create Payment

};


// Create Crypto Order
export const createCryptoOrder = async (user, cart, currency) => {
  try {
    const order = await createOrder(user, cart, 'CRYPTO', currency);
    return order;
  }
  catch (err) {
    console.log(err);
    return err;
  }

  // Request Crypto Payment

};

// Paypal Payment Calls

export const createPayment = async (order) => {
  const apiName = 'marketplace';
  const path = `/payment`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: {
      order,
    }
  };
  return await API.post(apiName, path, callOptions);
};


// Product Related Calls

export const getProductTypes = async () => {
  const apiName = 'marketplace';
  const path = '/enum/productType';
  const res = await API.get(apiName, path);
  return res.values;
};
export const getProductEditions = async () => {
  const apiName = 'marketplace';
  const path = '/enum/edition';
  const res = await API.get(apiName, path);
  return res.values;
};

export const getPacks = async () => {
  const apiName = 'marketplace';
  const path = '/enum/pack';
  return await API.get(apiName, path);
};

export const getRarities = async () => {
  const apiName = 'marketplace';
  const path = '/enum/rarity';
  return await API.get(apiName, path);
};

export const getVRarities = async () => {
  const apiName = 'marketplace';
  const path = '/enum/vRarity';
  return await API.get(apiName, path);
};

export const getBanishedIds = async () => {
  const apiName = 'marketplace';
  const path = '/enum/banishedIds';
  const result = await API.get(apiName, path);
  return result.values;
};

export const getDiscount = async (code) => {
  const apiName = 'marketplace';
  const path = `/discount/${code}`;
  return await API.get(apiName, path);
};

export const applyDiscount = (total, discount) => {
  if (!discount?.active || discount?.isPending) return total;
  switch (discount.type) {
    case 'percent':
      return (total - (total * discount.value));
    case 'dollar':
      return (total - discount.value);
    default:
      return (total);
  }
};



// Product Calls
export const getProducts = async () => {
  let skip = 0;
  let limit = 20;
  let products;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/product';
  const options = {
    queryStringParameters: {
      limit,
      skip,
      count: 'true',
    },
  };

  try {
    let res = await API.get(apiName, path, options);
    products = res.data;
    while (hasMore) {
      skip++;
      const options = {
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      products = [...products, ...res.data];
      if (res.data.length < 1) {
        hasMore = false;
      }
    }
    return products;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Products',
      error: err,
    });
  }

};


export const getProductById = async (id) => {
  const apiName = 'marketplace';
  const path = `/product/${id}`;
  return await API.get(apiName, path);
};


// Card Calls
export async function getCards() {
  let skip = 0;
  let limit = 200;
  let cards;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/card';
  const options = {
    response: true,
    queryStringParameters: { limit, count: true, skip: limit * skip },
  };

  try {
    let res = await API.get(apiName, path, options);
    cards = res.data.data;
    while (hasMore) {
      skip++;
      const options = {
        response: true,
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      cards = [...cards, ...res.data.data];
      if (res.data.data.length < 1) {
        hasMore = false;
      }
    }
    return cards;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Cards',
      error: err,
    });
  }
}

export async function getHeroes() {

  const apiName = 'marketplace';
  const path = '/card/mintcount';

  try {
    let res = await API.get(apiName, path);
    const { heroes } = res;
    return heroes;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Heroes',
      error: err,
    });
  }
}
// Rate Calls

export function fetchRates() {
  const apiName = 'marketplace';
  const path = '/rate';
  return API.get(apiName, path);
}

export const fetchCurrencies = async () => {
  const apiName = 'marketplace';
  const path = '/enum/currency';
  let currencies = await API.get(apiName, path);
  return currencies.values;
};


// Gas Price Calls

export const getGasPrice = async (chain) => {
  const apiName = 'marketplace';
  const path = chain ? `/gas-price/${chain}` : `/gas-price`;

  try {
    let { result } = await API.get(apiName, path);
    return result.FastGasPrice;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch gas price',
      error: err,
    });
  }
};

export const getMaxPriorityFee = async (chain) => {
  const apiName = 'marketplace';
  const path = chain ? `/gas-priority/${chain}` : `/gas-priority/polygon`;
  const options = {
    body: {
      jsonrpc: '2.0',
      method: 'eth_maxPriorityFeePerGas',
      params: [],
      id: 'latest',
    },
  };

  try {
    let { result } = await API.post(apiName, path, options);
    result = Web3.utils.hexToNumberString(result);
    return result;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch priority fee',
      error: err,
    });
  }
};

//NFT Order Calls


//get all orders

// get buyer orders by email

export const postNftOrder = async (orderInfo) => {
  const apiName = 'marketplace';
  const path = `/nft-order`;
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: orderInfo,
  };

  return await API.post(apiName, path, options);
};
export const updateNftOrder = async (orderId) => {
  const apiName = 'marketplace';
  const path = `/nft-order/${orderId}`;
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };

  return await API.put(apiName, path, options);
};

// ======================== External Requests ============================

// Get token metadata

export const getTokenMetadata = async (
  tokenId,
  contractAddress = config.nftAddress
) => {
  let metadataUrl;
  if (contractAddress.toLowerCase() !== config.nftAddress) {
    return;
    // To Do - fetch metadata url
  } else {
    metadataUrl = `https://meta.locgame.io/${toChecksumAddress(
      config.nftAddress
    )}/${tokenId}`;
  }
  return await API.get('global', metadataUrl);
};



// User Calls

/***************************************************************
* Create User 
* @param {string} userId
* @return {Promise} Promise resolves to user.
****************************************************************/
export const createUser = async (user) => {
  const apiName = 'marketplace';
  const path = `/user`;
  const callOptions = {
    body: {
      "cognito_username": user.cognito_username,
      "email": user.email,
      "email_verified": true,
      "first_name": " ",
      "last_name": " ",
      "avatar": "user.svg",
      "wallet": "",
      "roles": [],
      "wishlist": [],
      "name": "",
      "last_activity": Date.now(),
      "online": false,
      "resettable_resources": {
        "2": {}
      },
      "tutorial_data": {
        "completed_soft_tutorials": [],
        "current_step": 0
      },
      "resources": {
        "1": 0,
        "2": 0
      },
      "story_mode": {
        "last_unlocked_level": 2
      },
      "arena_data": {
        "max_rating": 0,
        "rating": 0,
        "state": 0,
        "tour_state": 0,
        "selected_deck": "63d7cfb334cce16e08e03147",
        "selected_deck_hash": ""
      }
    }
  };
  return await API.post(apiName, path, callOptions);
};

/***************************************************************
* Get single User from Database using Cognito Username
* @param {string} userId
* @return {Promise} Promise resolves to user.
****************************************************************/
export const fetchUserByCognitoId = async (userId) => {
  const apiName = 'marketplace';
  const path = `/user/${userId}`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  };
  return await API.get(apiName, path, callOptions);
};

/***************************************************************
* Get single User from Database using Id
* @param {string} email
* @return {Promise} Promise resolves to user.
****************************************************************/
export const fetchUserByEmail = async (email) => {
  const apiName = 'marketplace';
  const path = `/user/email/${email?.toLowerCase()?.trim()}`;

  return await API.get(apiName, path);
};


/***************************************************************
* Get single User from Database using Id
* @param {string} email
* @return {Promise} Promise resolves to user.
****************************************************************/
export const fetchUserByWallet = async (wallet, client = 'metamask') => {
  const apiName = 'marketplace';
  const path = `/user/wallet`;
  const callOptions = {
    response: true,
    body: { wallet: wallet?.toLowerCase()?.trim(), client }
  };

  return await API.post(apiName, path, callOptions);
};


/***************************************************************
* Update User In Database
* @param {Object} user
* @return {Promise} Promise resolves to updated user.
****************************************************************/
export const updateUserById = async (user) => {
  const apiName = 'marketplace';
  const path = `/user/${user._id}`;
  const callOptions = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    body: user
  };
  return await API.patch(apiName, path, callOptions);
};


// Merch Calls

export const fetchCountries = async () => {
  const apiName = 'global';
  const path = `https://api.printful.com/countries`;
  return await API.get(apiName, path);
};



/***************************************************************
* Get All Merch
* @return {Promise} Promise resolves to products.
****************************************************************/
export const getMerch = async () => {
  const apiName = 'marketplace';
  const path = `/product/merch`;

  return await API.get(apiName, path);
};


/***************************************************************
* Get All Merch
* @return {Promise} Promise resolves to products.
****************************************************************/
export const getMerchItem = async (id) => {
  const apiName = 'marketplace';
  const path = `/product/merch/${id}`;

  return await API.get(apiName, path);
};

/***************************************************************
* Get All Merch
* @return {Promise} Promise resolves to products.
****************************************************************/
export const getVariantData = async (ids) => {

  if (!Array.isArray(ids)) {
    ids = ids.join(',');
  }
  const apiName = 'marketplace';
  const path = `/printful/variants/`;
  const options = {
    queryStringParameters: {
      ids
    },
  };
  return await API.get(apiName, path, options);
}; 