import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '45px'};
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: ${({ margin }) => margin || '0'};
  position: relative;
`;

export const Label = styled.div`
  position: absolute;
  inset: 0;
  color: black;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  /* display: ${({ value }) => (value ? 'none' : 'flex')}; */
  opacity: ${({ value }) => (value ? '.3' : '1')};
  align-items: center;
  justify-content: center;
  z-index: 120;
  pointer-events: none;
  transition: opacity 0.2s ease;

  span {
    font-weight: 600;
    font-size: 14px;
    margin: 0 8px;
  }
`;

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  width: 100%;
  height: 25px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #d3d3d3;
  outline: none;
  border-radius: 50px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: url('/images/token.png');
    background-size: cover;
    /* background: #04aa6d;  */
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
  }

  &::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: url('/images/token.png');
    background-size: cover;
    /* background: #04aa6d; */
    border-radius: 50%;
    cursor: pointer;
  }
`;
