import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: ${({ theme }) => theme.navHeight};
  background: rgb(0, 0, 0);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
`;

export const Nav = styled.nav`
  background: transparent;
  position: fixed;
  width: 100%;
  max-width: 1280px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 30px;
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.navHeight};
  z-index: 30;

  .wrapper {
    display: flex;
    height: ${({ theme }) => theme.navHeight};
    align-items: center;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .mobile-toggle {
    width: 40px;
    height: ${({ theme }) => theme.navHeight};
    display: none;
  }

  @media screen and (max-width: 1023px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 730px) {
    padding: 0 1rem;
  }
  @media screen and (max-width: 680px) {
    .mobile-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Image = styled.img`
  width: 80px;
`;

export const Icon = styled.img`
  display: inline-block;
  height: 25px;
  width: 25px;
`;

export const NavMenuDesktop = styled.ul`
  display: flex;
  flex-grow: 1;
  height: ${({ theme }) => theme.navHeight};
  list-style: none;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 680px) {
    display: none;
  }
`;
export const NavMenuMobile = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  background-color: ${({ theme }) => theme.bgColor};
  padding: 5rem 0 1rem;

  &.visible {
    display: flex;
  }

  ul.submenu {
    display: flex;
    flex-direction: column;
  }

  li {
    border-bottom: 2px solid #202125;
    height: 60px;
  }

  .sub-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      border-bottom: 2px solid #202125;
      height: 60px;
    }
  }

  .sub-heading {
    font: 500 14px/24px TTHoves;
    color: ${({ theme }) => theme.fontColor};
    padding: 0 15px;
    max-height: 60px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.6;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.navHoverBg};
      opacity: 1;
    }
  }
`;

export const CloseBtn = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13px;
  right: 17px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;

// export const Link = styled.li`
//   display: flex;
//   height: 100%;
//   align-items: center;
//   font: ${({ theme }) => theme.font.button};
//   font-weight: 600;
//   padding: 1rem;
//   cursor: pointer;

//   &:hover {
//     background-color: ${({ theme }) => theme.navHoverBg};
//   }
// `;

export const Link = styled.li`
  background-color: inherit;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.fontColor};
  font: ${({ theme }) => theme.font.body};
  font-size: 20px;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  text-transform: uppercase;

  &:hover {
    background-color: ${({ theme }) => theme.navHoverBg};
  }
`;
