import React from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapper, Modal, CloseBtn } from './ErrorsModal.styles';

const ErrorsModal = ({ title, errors, action, sendClose }) => {
  return (
    <ModalWrapper>
      <Modal>
        {sendClose && <CloseBtn onClick={sendClose} />}
        <h3>{title}</h3>
        {errors.map((error) => (
          <h4>{error}</h4>
        ))}
        {action && <h3 className='action'>{action}</h3>}
      </Modal>
    </ModalWrapper>
  );
};

export default ErrorsModal;
