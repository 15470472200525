export const USER_REJECTED = 4001;
export const INVALID_PARAMETERS = -32602;
export const INTERNAL_ERROR = -32603;
export const INVALID_OPCODE = -32000;
export const INSUFFICIENT_FUNDS = "transfer amount exceeds balance";
export const SLOW_TX = 'Unable to send funds';
export const chains = {
  "5": {
    id: `0x${Number(5).toString(16)}`,
    name: 'Goerli',
  },
  "137": {
    id: `0x${Number(137).toString(16)}`,
    name: 'Polygon',
  },
  "80001": {
    id: `0x${Number(80001).toString(16)}`,
    name: 'Mumbai',
  }
};