import React, { useEffect, useState } from 'react';
import { Container } from './ColorPicker.styles';

const ColorPicker = ({ color, selectedColor, count, onClick }) => {
  const { name, code } = color;
  const [selected, selectedSet] = useState(false);

  useEffect(() => {
    if (selectedColor === name) {
      selectedSet(true);
    } else {
      selectedSet(false);
    }
  }, [name, selectedColor]);

  return (
    <Container isSelected={selected} color={code} onClick={onClick}>
      {count > 1 && selected && name.toLowerCase() === 'black' && (
        <img src='/images/icon--X-white.svg' alt='Selected' />
      )}
      {count > 1 && selected && name.toLowerCase() !== 'black' && (
        <img src='/images/icon--X-black.svg' alt='Selected' />
      )}
    </Container>
  );
};

export default ColorPicker;
